import React from "react"
import Panel from "../components/panel"
// import '../styles/programNews.css'

const getResizedImg = (node, size) => {
    if (node.frontmatter === undefined) return '';
    let srcSet = node.frontmatter.featuredImage.childImageSharp.fluid.srcSet;
    console.log(srcSet);
    console.log(size);
    let imgPath = srcSet.split(',').find(elem => elem.indexOf(size.toString()) !== -1).trim().split(' ')[0] || "";
    return imgPath || '';
}

export default ({ pageContext, data }) => {

    const gifUrl = `https://totvsnews.engpro.totvs.com.br/${pageContext.module}/${pageContext.country}/${pageContext.language}/images/${pageContext.imageInfo.filename}.gif`
    const assetHeader = data.assetHeader.nodes[0];
    const headerImg = getResizedImg(assetHeader, 1024);

    return (
        <div style={{textAlign: "center", alignItems: "center"}}>
            {console.log(pageContext)}
            <header>
                <Panel title={`ASSISTA A DEMO DESTA ROTINA`} imgPath={headerImg} style={{margin:"0px auto","max-width": "1024px",width: "auto"}}></Panel>
            </header>

            <section className="gifArea">
                <img src={gifUrl} width="720" height="382" />
            </section>
            <footer>
                <a href={pageContext.imageInfo.link} target="_blank">Clique aqui e assista o vídeo</a>
            </footer>
        </div>
    )
}

export const query = graphql`
query {
    assetHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "panel-header"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                    fluid(maxWidth: 1024, quality: 80) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
            }
        }
    }
}
`